import { createArray } from 'src/utils';

export const MERCHANTS = [
  // {
  //   id: 0,
  //   imgLogo: require('src/images/npay/merchants/logos/logo-0.png'),
  //   destinationURL: 'https://www.qoo10.jp/',
  // },
  {
    id: 1,
    imgLogo: require('src/images/npay/merchants/logos/logo-1.png'),
    destinationURL: 'http://shop-list.com/',
  },
  {
    id: 2,
    imgLogo: require('src/images/npay/merchants/logos/logo-2.png'),
    destinationURL: 'https://www.buyma.com/',
  },
  {
    id: 3,
    imgLogo: require('src/images/npay/merchants/logos/logo-3.png'),
    destinationURL: 'https://fril.jp/',
  },
  {
    id: 4,
    imgLogo: require('src/images/npay/merchants/logos/logo-4.png'),
    destinationURL: 'http://www.urban-research.jp/',
  },
  {
    id: 5,
    imgLogo: require('src/images/npay/merchants/logos/logo-5.png'),
    destinationURL: 'https://www.shipsltd.co.jp/',
  },
  {
    id: 6,
    imgLogo: require('src/images/npay/merchants/logos/logo-6.png'),
    destinationURL: 'https://shop.newbalance.jp/shop/default.aspx',
  },
  {
    id: 7,
    imgLogo: require('src/images/npay/merchants/logos/logo-7.png'),
    destinationURL: 'https://www.magaseek.com/top/index/tp_1',
  },
  {
    id: 8,
    imgLogo: require('src/images/npay/merchants/logos/logo-8.png'),
    destinationURL: 'https://www.peachjohn.co.jp/',
  },
  {
    id: 10,
    imgLogo: require('src/images/npay/merchants/logos/logo-10.png'),
    destinationURL: 'https://wego.jp/',
  },
  {
    id: 11,
    imgLogo: require('src/images/npay/merchants/logos/logo-11.png'),
    destinationURL: 'https://www.junonline.jp/',
  },
  {
    id: 12,
    imgLogo: require('src/images/npay/merchants/logos/logo-12.png'),
    destinationURL: 'https://www.supersports.com/ja-jp/xebio',
  },
  {
    id: 13,
    imgLogo: require('src/images/npay/merchants/logos/logo-13.png'),
    destinationURL: 'https://yz-store.com/',
  },
  {
    id: 15,
    imgLogo: require('src/images/npay/merchants/logos/logo-15.png'),
    destinationURL:
      'https://dazzystore.com/?utm_source=Paidy&utm_medium=banner&utm_campaign=cp2212',
  },
  {
    id: 16,
    imgLogo: require('src/images/npay/merchants/logos/logo-16.png'),
    destinationURL: 'https://5-fifth.com/',
  },
  {
    id: 17,
    imgLogo: require('src/images/npay/merchants/logos/logo-17.png'),
    destinationURL: 'https://nairo.jp/',
  },
  {
    id: 18,
    imgLogo: require('src/images/npay/merchants/logos/logo-18.png'),
    destinationURL: 'http://www.dreamvs.jp/',
  },
  {
    id: 19,
    imgLogo: require('src/images/npay/merchants/logos/logo-19.png'),
    destinationURL: 'http://d-rw.com/Paidy-cp2212',
  },
  {
    id: 20,
    imgLogo: require('src/images/npay/merchants/logos/logo-20.png'),
    destinationURL: 'https://www.underarmour.co.jp/',
  },
  {
    id: 21,
    imgLogo: require('src/images/npay/merchants/logos/logo-21.png'),
    destinationURL: 'https://punyus.jp/',
  },
  {
    id: 22,
    imgLogo: require('src/images/npay/merchants/logos/logo-22.png'),
    destinationURL: 'https://store.rili.tokyo/',
  },
  {
    id: 23,
    imgLogo: require('src/images/npay/merchants/logos/logo-23.png'),
    destinationURL: 'https://www.y-aoyama.jp/',
  },
  {
    id: 24,
    imgLogo: require('src/images/npay/merchants/logos/logo-24.png'),
    destinationURL: 'https://joggo.jp/',
  },
  {
    id: 25,
    imgLogo: require('src/images/npay/merchants/logos/logo-25.png'),
    destinationURL: 'https://www.paul-joe-beaute.com/',
  },
  {
    id: 26,
    imgLogo: require('src/images/npay/merchants/logos/logo-26.png'),
    destinationURL: 'https://247lingerie.co/',
  },
  {
    id: 27,
    imgLogo: require('src/images/npay/merchants/logos/logo-27.png'),
    destinationURL: 'https://www.uktsc.com/',
  },
  {
    id: 28,
    imgLogo: require('src/images/npay/merchants/logos/logo-28.png'),
    destinationURL: 'https://outlet.newbalance.jp/shop/',
  },
  // {
  //   id: 30,
  //   imgLogo: require('src/images/npay/merchants/logos/logo-30.png'),
  //   destinationURL: 'https://www.qoo10.jp/',
  // },
  {
    id: 31,
    imgLogo: require('src/images/npay/merchants/logos/logo-31.png'),
    destinationURL: 'http://shop-list.com/',
  },
  {
    id: 32,
    imgLogo: require('src/images/npay/merchants/logos/logo-32.png'),
    destinationURL: 'https://www.paul-joe-beaute.com/',
  },
  {
    id: 34,
    imgLogo: require('src/images/npay/merchants/logos/logo-34.png'),
    destinationURL: 'https://www.airtrip.jp/?source=airtrip.jp',
  },
  {
    id: 35,
    imgLogo: require('src/images/npay/merchants/logos/logo-35.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
  },
  {
    id: 36,
    imgLogo: require('src/images/npay/merchants/logos/logo-36.png'),
    destinationURL: 'https://travelist.jp/',
  },
  {
    id: 37,
    imgLogo: require('src/images/npay/merchants/logos/logo-37.png'),
    destinationURL: 'https://dwango-ticket.jp/',
  },
  {
    id: 38,
    imgLogo: require('src/images/npay/merchants/logos/logo-38.png'),
    destinationURL: 'https://www.dmm.com/',
  },
  {
    id: 39,
    imgLogo: require('src/images/npay/merchants/logos/logo-39.png'),
    destinationURL: 'https://17.live/',
  },
  {
    id: 41,
    imgLogo: require('src/images/npay/merchants/logos/logo-41.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  {
    id: 42,
    imgLogo: require('src/images/npay/merchants/logos/logo-42.png'),
    destinationURL: 'https://yuyu-tei.jp/',
  },
  {
    id: 44,
    imgLogo: require('src/images/npay/merchants/logos/logo-44.png'),
    destinationURL: 'https://francfranc.com/',
  },
  {
    id: 45,
    imgLogo: require('src/images/npay/merchants/logos/logo-45.png'),
    destinationURL: 'https://rasik.style/',
  },
  {
    id: 47,
    imgLogo: require('src/images/npay/merchants/logos/logo-47.png'),
    destinationURL: 'https://www.tansu-gen.jp/',
  },
  {
    id: 48,
    imgLogo: require('src/images/npay/merchants/logos/logo-48.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
  },
  {
    id: 49,
    imgLogo: require('src/images/npay/merchants/logos/logo-49.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  {
    id: 50,
    imgLogo: require('src/images/npay/merchants/logos/logo-50.png'),
    destinationURL: 'https://shop.tsukumo.co.jp/',
  },
  {
    id: 51,
    imgLogo: require('src/images/npay/merchants/logos/logo-51.png'),
    destinationURL: 'https://www.irisplaza.co.jp/',
  },
  {
    id: 52,
    imgLogo: require('src/images/npay/merchants/logos/logo-52.png'),
    destinationURL: 'https://www.unico-fan.co.jp/shop/default.aspx',
  },
  {
    id: 53,
    imgLogo: require('src/images/npay/merchants/logos/logo-53.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  {
    id: 54,
    imgLogo: require('src/images/npay/merchants/logos/logo-54.png'),
    destinationURL: 'https://www.buyma.com/',
  },
  {
    id: 55,
    imgLogo: require('src/images/npay/merchants/logos/logo-55.png'),
    destinationURL: 'https://joggo.jp/',
  },
  {
    id: 56,
    imgLogo: require('src/images/npay/merchants/logos/logo-56.png'),
    destinationURL: 'https://greenfunding.jp/',
  },
  {
    id: 'applied-net',
    imgLogo: require('src/images/npay/merchants/logos/logo-applied-net.png'),
    destinationURL: 'https://shop.applied-net.co.jp',
  },
  {
    id: 57,
    imgLogo: require('src/images/npay/merchants/logos/logo-57.png'),
    destinationURL: 'https://www.innocent-style.com/#top',
  },
  {
    id: 58,
    imgLogo: require('src/images/npay/merchants/logos/logo-58.png'),
    destinationURL: 'https://www.hoshinoresorts.com/',
  },
  {
    id: 59,
    imgLogo: require('src/images/npay/merchants/logos/logo-59.png'),
    destinationURL: 'https://shop.hololivepro.com/',
  },
  {
    id: 60,
    imgLogo: require('src/images/npay/merchants/logos/logo-60.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  {
    id: 61,
    imgLogo: require('src/images/npay/merchants/logos/logo-61.png'),
    destinationURL: 'https://dwango-ticket.jp/',
  },
  {
    id: 62,
    imgLogo: require('src/images/npay/merchants/logos/logo-62.png'),
    destinationURL: 'https://fril.jp/',
  },
  {
    id: 63,
    imgLogo: require('src/images/npay/merchants/logos/logo-63.png'),
    destinationURL: 'https://kurand.jp/',
  },
  {
    id: 64,
    imgLogo: require('src/images/npay/merchants/logos/logo-64.png'),
    destinationURL: 'https://joggo.jp/',
  },
  {
    id: 65,
    imgLogo: require('src/images/npay/merchants/logos/logo-65.png'),
    destinationURL: 'https://yuyu-tei.jp/',
  },
  {
    id: 66,
    imgLogo: require('src/images/npay/merchants/logos/logo-66.png'),
    destinationURL: 'https://greenfunding.jp/',
  },
  {
    id: 67,
    imgLogo: require('src/images/npay/merchants/logos/logo-67.png'),
    destinationURL: 'https://www.supersports.com/ja-jp/xebio',
  },
  {
    id: 68,
    imgLogo: require('src/images/npay/merchants/logos/logo-68.png'),
    destinationURL: 'https://www.underarmour.co.jp/',
  },
  {
    id: 69,
    imgLogo: require('src/images/npay/merchants/logos/logo-69.png'),
    destinationURL: 'https://www.irisplaza.co.jp/',
  },
  // {
  //   id: 71,
  //   imgLogo: require('src/images/npay/merchants/logos/logo-71.png'),
  //   destinationURL: 'https://jp.atv.direct/',
  // },
  {
    id: 72,
    imgLogo: require('src/images/npay/merchants/logos/logo-72.png'),
    destinationURL: 'https://vipliner.biz/',
  },
  {
    id: 73,
    imgLogo: require('src/images/npay/merchants/logos/logo-73.png'),
    destinationURL: 'https://activityjapan.com/',
  },
  {
    id: 74,
    imgLogo: require('src/images/npay/merchants/logos/logo-74.png'),
    destinationURL: 'https://www.withlive.jp/',
  },
  {
    id: 75,
    imgLogo: require('src/images/npay/merchants/logos/logo-75.png'),
    destinationURL: 'https://www.axes-net.com/',
  },
  {
    id: 76,
    imgLogo: require('src/images/npay/merchants/logos/logo-76.png'),
    destinationURL: 'https://sisi.tokyo/shop',
  },
  {
    id: 77,
    imgLogo: require('src/images/npay/merchants/logos/logo-77.png'),
    destinationURL: 'https://runway-webstore.com/',
  },
  {
    id: 78,
    imgLogo: require('src/images/npay/merchants/logos/logo-78.png'),
    destinationURL: 'https://www.samsung.com/jp/offer/',
  },
  {
    id: 79,
    imgLogo: require('src/images/npay/merchants/logos/logo-79.png'),
    destinationURL: 'https://emma-sleep-japan.com/',
  },
  {
    id: 80,
    imgLogo: require('src/images/npay/merchants/logos/logo-80.png'),
    destinationURL:
      'https://mechacomi.jp/?p1=paidy_shop_info&utm_campaign=paidy_shop_info&utm_source=paidy&utm_medium=referral',
  },
  {
    id: 81,
    imgLogo: require('src/images/npay/merchants/logos/logo-81.png'),
    destinationURL: 'https://www.samsung.com/jp/offer/',
  },
  {
    id: 82,
    imgLogo: require('src/images/npay/merchants/logos/logo-82.png'),
    destinationURL: 'https://www.ralphlauren.co.jp/',
  },
  {
    id: 83,
    imgLogo: require('src/images/npay/merchants/logos/logo-83.png'),
    destinationURL: 'https://wrinkfade.com/',
  },
  {
    id: 84,
    imgLogo: require('src/images/npay/merchants/logos/logo-84.png'),
    destinationURL: 'https://furyu-hm.com/',
  },
  {
    id: 85,
    imgLogo: require('src/images/npay/merchants/logos/logo-85.png'),
    destinationURL: 'https://order.mandarake.co.jp/order/',
  },
  {
    id: 86,
    imgLogo: require('src/images/npay/merchants/logos/logo-86.png'),
    destinationURL: 'https://shop.aimerfeel.jp/shop/default.aspx',
  },
  {
    id: 87,
    imgLogo: require('src/images/npay/merchants/logos/logo-87.png'),
    destinationURL: 'https://shop.njpw.co.jp/',
  },
  {
    id: 88,
    imgLogo: require('src/images/npay/merchants/logos/logo-88.png'),
    destinationURL: 'https://mall.fany.lol/',
  },
  {
    id: 89,
    imgLogo: require('src/images/npay/merchants/logos/logo-89.png'),
    destinationURL: 'https://www.anyahindmarch.jp/',
  },
  {
    id: 90,
    imgLogo: require('src/images/npay/merchants/logos/logo-90.png'),
    destinationURL: 'https://www.torecolo.jp/shop/default.aspx',
  },
  {
    id: 91,
    imgLogo: require('src/images/npay/merchants/logos/logo-91.png'),
    destinationURL: 'https://bruno-onlineshop.com/',
  },
  {
    id: 92,
    imgLogo: require('src/images/npay/merchants/logos/logo-92.png'),
    destinationURL: 'https://shop.njpw.co.jp/',
  },
  {
    id: 93,
    imgLogo: require('src/images/npay/merchants/logos/logo-93.png'),
    destinationURL: 'https://mall.fany.lol/',
  },
  {
    id: 94,
    imgLogo: require('src/images/npay/merchants/logos/logo-94.png'),
    destinationURL: 'https://converse.co.jp/',
  },
  {
    id: 95,
    imgLogo: require('src/images/npay/merchants/logos/logo-95.png'),
    destinationURL: 'https://bonbon92.com/',
  },
  {
    id: 96,
    imgLogo: require('src/images/npay/merchants/logos/logo-96.png'),
    destinationURL: 'https://lifes-203.com/',
  },
  {
    id: 97,
    imgLogo: require('src/images/npay/merchants/logos/logo-97.png'),
    destinationURL: 'https://citizen.jp/',
  },
  {
    id: 98,
    imgLogo: require('src/images/npay/merchants/logos/logo-98.png'),
    destinationURL: 'https://www.sixty-percent.com/',
  },
  {
    id: 99,
    imgLogo: require('src/images/npay/merchants/logos/logo-99.png'),
    destinationURL: 'https://www.samantha.co.jp/shop/c/cST',
  },
  {
    id: 100,
    imgLogo: require('src/images/npay/merchants/logos/logo-100.png'),
    destinationURL: 'https://whowatch.tv/',
  },
  {
    id: 101,
    imgLogo: require('src/images/npay/merchants/logos/logo-101.png'),
    destinationURL: 'https://cameranonaniwa.jp/shop/',
  },
  {
    id: 102,
    imgLogo: require('src/images/npay/merchants/logos/logo-002.png'),
    destinationURL: 'https://www.hoshinoresorts.com/',
  },
  {
    id: 103,
    imgLogo: require('src/images/npay/merchants/logos/logo-103.png'),
    destinationURL: 'https://www.hay-japan.com/',
  },
  {
    id: 104,
    imgLogo: require('src/images/npay/merchants/logos/logo-104.png'),
    destinationURL: 'https://orobianco-jp.com/top/CSfTop.jsp',
  },
  {
    id: 105,
    imgLogo: require('src/images/npay/merchants/logos/logo-105.png'),
    destinationURL: 'https://ec.geo-online.co.jp/shop/',
  },
  {
    id: 106,
    imgLogo: require('src/images/npay/merchants/logos/logo-106.png'),
    destinationURL: 'https://www.frontier-direct.jp/',
  },
  {
    id: 107,
    imgLogo: require('src/images/npay/merchants/logos/logo-107.png'),
    destinationURL: 'https://store.united-arrows.co.jp/',
  },
  {
    id: 108,
    imgLogo: require('src/images/npay/merchants/logos/logo-108.png'),
    destinationURL: 'https://kingjim.com',
  },
  {
    id: 109,
    imgLogo: require('src/images/npay/merchants/logos/logo-109.png'),
    destinationURL: 'https://www.deandeluca.co.jp/shop/',
  },
  {
    id: 110,
    imgLogo: require('src/images/npay/merchants/logos/logo-mederi.png'),
    destinationURL: 'https://mederi.jp/',
  },
  {
    id: 111,
    imgLogo: require('src/images/npay/merchants/logos/logo-katespade.png'),
    destinationURL: 'https://www.katespade.jp/',
  },
  {
    id: 112,
    imgLogo: require('src/images/npay/merchants/logos/brooksbrother.png'),
    destinationURL: 'https://www.brooksbrothers.co.jp/',
  },
  {
    id: 113,
    imgLogo: require('src/images/npay/merchants/logos/aviot.png'),
    destinationURL: 'https://shop.aviot.jp/',
  },
  {
    id: 114,
    imgLogo: require('src/images/npay/merchants/logos/dinos.png'),
    destinationURL: 'https://www.dinos.co.jp/',
  },
  {
    id: 115,
    imgLogo: require('src/images/npay/merchants/logos/gelato-pique.png'),
    destinationURL: 'https://gelatopique.com/',
  },
  {
    id: 116,
    imgLogo: require('src/images/npay/merchants/logos/mash-store.png'),
    destinationURL: 'https://apps.apple.com/us/app/mash-store/id1623326415',
  },
  {
    id: 117,
    imgLogo: require('src/images/npay/merchants/logos/snidel.png'),
    destinationURL: 'https://snidel.com/',
  },
  {
    id: 118,
    imgLogo: require('src/images/npay/merchants/logos/casio.jpg'),
    destinationURL:
      'https://www.casio.com/jp/online-store/?utm_source=google&utm_medium=paidsearch&utm_campaign=watch_co-no-al-te-te_202408_jp&utm_content=%7C%7C%7C240801%7Ctext%7C%7C%7C%7C&gad_source=1&gclid=Cj0KCQjw2ou2BhCCARIsANAwM2G0i9ABULESKbIfndskkkxR847boq4uuCeywIgF0lQNmO1pxeNRKvoaAmuJEALw_wcB',
  },
  {
    id: 119,
    imgLogo: require('src/images/npay/merchants/logos/apahotel.jpg'),
    destinationURL: 'https://www.apahotel.com/',
  },
  {
    id: 120,
    imgLogo: require('src/images/npay/merchants/logos/dlsite.jpg'),
    destinationURL: 'https://www.dlsite.com/index.html',
  },
  {
    id: 121,
    imgLogo: require('src/images/npay/merchants/logos/yogibo.jpg'),
    destinationURL: 'https://www.yogibo.jp/',
  },
  {
    id: 122,
    imgLogo: require('src/images/npay/merchants/logos/tag-heuer.png'),
    destinationURL: 'https://www.tagheuer.com/jp/ja/',
  },
  {
    id: 123,
    imgLogo: require('src/images/npay/merchants/logos/nell.png'),
    destinationURL: 'https://nell.life/',
  },
];

export const MERCHANT_GROUPED_BY_CATEGORY = [
  {
    categoryValue: 1,
    merchantIds: [
      2,
      1,
      3,
      13,
      19,
      86,
      8,
      15,
      4,
      107,
      114,
      115,
      116,
      117,
      10,
      77,
      25,
      12,
      94,
      95,
      87,
      11,
      6,
      98,
      88,
      20,
      82,
      28,
      118,
      22,
      99,
      21,
      23,
      5,
      96,
      26,
      112,
      24,
      17,
      75,
      104,
      89,
      111,
      122,
      97,
    ],
  },
  {
    categoryValue: 2,
    merchantIds: [...createArray(31, 32), 76, 83],
  },
  {
    categoryValue: 3,
    merchantIds: [35, 34, 119, 72, 102, 36, 37, 73],
  },
  {
    categoryValue: 4,
    merchantIds: [38, 120, 39, 100, 85, 42, 74, 41, 90, 84],
  },
  {
    categoryValue: 5,
    merchantIds: [
      54,
      53,
      105,
      44,
      78,
      48,
      45,
      51,
      47,
      49,
      114,
      106,
      50,
      56,
      123,
      113,
      79,
      55,
      116,
      52,
      91,
      103,
      118,
      'applied-net',
      108,
    ],
  },
  {
    categoryValue: 6,
    merchantIds: [
      62,
      59,
      120,
      60,
      110,
      63,
      85,
      69,
      65,
      109,
      67,
      74,
      92,
      58,
      80,
      93,
      68,
      61,
      66,
      64,
      81,
      108,
      101,
    ],
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'ファッション' },
  { value: 2, label: 'コスメ・カラコン' },
  { value: 3, label: '旅行・チケット予約' },
  { value: 4, label: 'ゲーム・動画・ライブ配信など' },
  { value: 5, label: '家電・楽器・インテリア' },
  { value: 6, label: 'その他' },
];

export const FIXED_MERCHANTS = [
  {
    id: 'amazon',
    imgLogo: require('src/images/npay/merchants/logos/logo-001.png'),
    destinationURL: 'https://www.amazon.co.jp/',
  },
  {
    id: 'hoshino',
    imgLogo: require('src/images/npay/merchants/logos/logo-002.png'),
    destinationURL: 'https://www.hoshinoresorts.com/',
  },
  {
    id: 'dmm',
    imgLogo: require('src/images/npay/merchants/logos/logo-38.png'),
    destinationURL: 'https://www.dmm.com/',
  },
];

export const MERCHANTS_GROUPED_BY_FIXED_TAGS = [
  {
    categoryValue: 0,
    merchantIds: ['amazon', 'dmm', 'hoshino'],
  },
];

export const FIXES_TAGS = [
  {
    value: 0,
    label: '人気のお店',
  },
];
